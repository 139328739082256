import { computed, Ref } from "vue";
import { IField } from "~/entities/field";
import { useCollectionFields } from "./useCollectionFields";

export const useCollectionFieldsExcluding = (
  collectionName: Ref<string>,
  excludedFieldNames: string[],
) => {
  const { fields: collectionFields } = useCollectionFields(collectionName);

  const fields = computed<Record<string, IField>>(() => {
    const map: Record<string, IField> = {};

    const fieldNames = Object.keys(collectionFields.value);

    for (const fieldName of fieldNames) {
      if (!excludedFieldNames.includes(fieldName)) {
        map[fieldName] = collectionFields.value[fieldName];
      }
    }

    return map;
  });

  return { fields };
};
